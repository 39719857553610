.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.contact-form textarea {
    resize: vertical;
}
.contact-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.shop-image {
    max-width: 400px;
    display: block;
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.address {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}
.address-details {
    margin-bottom: 100px;
}